﻿import {get, $, $$, postDataText, remove, show, isHidden} from "@/Utils";
import { initTabs } from "@/sharedComponents/Tabs";
import { initCarousels } from "@/sharedComponents/Carousel/Carousel"

function initLoadMoreNews() {
    $$("[data-load-more]")?.forEach(x => (x as Element).addEventListener("click", (e) => {
        const target = e.currentTarget as HTMLElement;
        const container = target.closest(".tab-content");
        const category = container.hasAttribute("data-category") ? container.id : "";
        const newsContainer = container.querySelector("[data-news-list]");
        const loadedCount = newsContainer.querySelectorAll("[data-news-item]")?.length ?? 0;
        const data = { category: category, loadedCount: loadedCount, keywords: getKeywords() };
        const callback = (result: string) => {
          const initialLoad = isHidden(target);
            const div = document.createElement("div");
            div.innerHTML = result;
            show(target);
            const newsItems = div.querySelectorAll("li");
            newsItems.forEach(x => newsContainer.append(x));
            const hasMore = div.querySelector("[data-has-more]")?.getAttribute("data-has-more");
            if (hasMore === "False") {
              remove(target);
          }
          if (!initialLoad) {
            const tabLink = $(`[data-tab-id="${container.id}"]`);
            const path = tabLink.getAttribute("data-path");
            const pathParts = path.split("/").filter(x => x);
            let page = parseInt(pathParts[pathParts.length - 1]);
            if (isNaN(page)) {
              pathParts.push("2");
            }
            else {
              pathParts[pathParts.length - 1] = (++page).toString();
            }
            const newPath = "/" + pathParts.join("/");
            tabLink.setAttribute("data-path", newPath);
            history.replaceState(null, null, location.href.replace(location.pathname, newPath));
          }
        }
        postDataText(window.config.loadMoreNewsUrl, data, callback);

    }));
}

function initCategoryTabClick() {
  $$(".news-landing .tabs-menu__item")?.forEach(x => {
    if (x.classList.contains("tabs-menu__item--active")) {
      x.setAttribute("data-loaded", "true");
    }
      x.addEventListener("click", () => {
        if (!x.getAttribute("data-loaded")) {
          categoryInitialLoad(x);
          x.setAttribute("data-loaded", "true");
        }
        history.replaceState(null, null, location.href.replace(location.pathname, "/" + x.getAttribute("data-path")));
      });

    });
}

function categoryInitialLoad(elem: HTMLElement) {
  const container = get(elem.getAttribute("data-tab-id"));
  (container.querySelector("[data-load-more]") as HTMLElement).click();
}

function getKeywords() {
    const keywords: string[] = [];
    $$("[data-selected]").forEach(x => keywords.push(x.getAttribute("data-keyword")));
    return keywords;
}

function initNewsList() {
    initLoadMoreNews();
    initTabs();
    initCategoryTabClick();
    initCarousels();
    document.body.style.overflowAnchor = "none";
}

window.addEventListener("load", () => {
    initNewsList();
});